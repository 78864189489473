<template>
  <PageLayout>
    <router-view />
    <template #aside>
      <MentorSideBar />
    </template>
    <template #modals>
      <ModalsContainer />
    </template>
  </PageLayout>
</template>

<script>
import PageLayout from "./PageLayout";
import MentorSideBar from "@/components/sidebar/MentorSideBar";
import SelectOrganizationModal from "@/components/mentor/modals/SelectOrganizationModal.vue";
import { getToken } from "@/services/api";

export default {
  name: "MentorLayout",
  components: { PageLayout, MentorSideBar },
  data() {
    return {
      timer: null,
    };
  },
  computed: {
    organizations() {
      return this.$store.state.mentor.organizations;
    },
  },
  mounted() {
    // если нет организаций то покажем модалку
    if (!this.organizations.length) {
      this.showCreateOrganizationModal();
    }
    this.timer = setInterval(() => {
      const token = getToken();
      if (!token) {
        clearInterval(this.timer);
        window.location.href = `${window.location.origin}/login`;
      }
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    showCreateOrganizationModal() {
      this.$modal.show(
        SelectOrganizationModal,
        {},
        {
          adaptive: true,
          height: "auto",
          scrollable: true,
          minHeight: 200,
          focusTrap: true,
          clickToClose: false,
        }
      );
    },
  },
};
</script>

<style lang="less"></style>
